<template>
  <v-form class="ma-2">
    <v-row dense>
      <div class="d-flex">
        <v-text-field
          v-model="search"
          outlined
          dense
          hide-details
          clearable
          class="ma-2"
          label="Buscar usuario"
          prepend-inner-icon="mdi-magnify"
          @change="onSelectedSearch"
        ></v-text-field>
        <v-btn @click="onSelectedSearch" class="primary ma-2">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-select
        v-model="selectedStatus"
        item-value="value"
        item-text="name"
        outlined
        dense
        hide-details
        class="ma-2"
        :items="getTrackingStatusOptions"
        @change="onSelectedStatusChange"
        label="Estatus"
      ></v-select>
      <v-select
        outlined
        dense
        hide-details
        class="ma-2"
        item-value="id"
        item-text="name"
        v-model="selectedGroups"
        :items="getAllGroups"
        label="Grupos"
        multiple
        @blur="onGroupChanges"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip class="mt-2" v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption"
            >(+{{ selectedGroups.length - 1 }} otras)</span
          >
        </template>
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
      </v-select>
    </v-row>
  </v-form>
</template>

<script>
import {
  TRACKING_SET_SELECTED_STATUS,
  TRACKING_SET_SEARCH,
  TRACKING_SET_SELECTED_GROUPS
} from '@/store/mutations.type'
import { to } from '@/helpers'
import { TRACKING_GET_TRACKING } from '@/store/actions.type'
import { mapGetters } from 'vuex'
export default {
  name: 'STrackingSidebarFilters',
  data() {
    return {
      selectedStatus: null,
      transition: 'fade-transition',
      search: '',
      selectedGroups: []
    }
  },
  computed: {
    ...mapGetters([
      'getTrackingStatusOptions',
      'getAllGroups'
    ])
  },
  methods: {
    async getTracking() {
      const [err] = await to(this.$store.dispatch(TRACKING_GET_TRACKING))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    onSelectedSearch(e) {
      this.$store.commit(TRACKING_SET_SEARCH, this.search)
    },
    onSelectedStatusChange() {
      this.$store.commit(TRACKING_SET_SELECTED_STATUS, this.selectedStatus)
      this.getTracking()
    },
    onGroupChanges() {
      this.$store.commit(
        TRACKING_SET_SELECTED_GROUPS,
        this.selectedGroups
      )
      this.getTracking()
    }
  }
}
</script>
