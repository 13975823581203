<template>
  <v-row no-gutters>
    <v-col cols="3" md="3" xl="2" >
      <s-tracking-sidebar></s-tracking-sidebar>
    </v-col>
    <v-col>
      <s-tracking-map></s-tracking-map>
    </v-col>
    <v-col cols="3" md="3" xl="2" v-if="getTrackingTrack">
      <s-tracking-user-sidebar></s-tracking-user-sidebar>
    </v-col>
  </v-row>
</template>
<script>
import STrackingMap from '@/components/app/tracking/s-tracking-map'
import STrackingSidebar from '@/components/app/tracking/s-tracking-sidebar'
import STrackingUserSidebar from '@/components/app/tracking/s-tracking-user-sidebar'
import { mapGetters, mapActions } from 'vuex'
import { SETTINGS_HIDE_SIDEBAR, GROUPS_GET_GROUPS } from '@/store/actions.type'

export default {
  name: 'Tracking',
  props: {},
  data() {
    return {
      isLoading: true,
      error: null
    }
  },
  computed: {
    ...mapGetters(['getTrackingTrack'])
  },
  methods: {
    ...mapActions({
      hideSideBar: SETTINGS_HIDE_SIDEBAR,
      getGroups: GROUPS_GET_GROUPS
    })
  },
  mounted() {
    this.hideSideBar()
    this.getGroups()
  },
  components: { STrackingSidebar, STrackingMap, STrackingUserSidebar }
}
</script>
