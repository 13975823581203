<template>
  <GmapMap
    ref="gmap"
    :center="center"
    v-show="!getTrackingIsLoading"
    :zoom="zoom"
    :options="options"
    style="width: 100%; height: 100%;">
    <GmapCluster @click="trakedUser($event)">
      <!-- <google-map-cluster> -->
      <GmapMarker
        :title="track.id"
        :key="track.id"
        v-for="(track) in getTrackingTracking"
        :position="track.position"
        :clickable="true"
        @click="trackClicked(track)"
        :icon="getMarkerIcon(track)"

      />
      <!-- </google-map-cluster> -->
  </GmapCluster>
  <v-dialog v-model="dialog" max-width="480">
      <v-card>
        <div :key="track.id" @click="dialog = false;trackClicked(track)" v-for="track in trackerUsers"
          class="modal-cluster">
          <div class="d-flex justify-cente ma-4" >
            <v-list-item-avatar>
                      <v-avatar color="grey">
                        <span class="white--text headline">{{getInitials(track.user.displayName)}}</span>
                      </v-avatar>
                    </v-list-item-avatar>
          </div>
          <v-card-text>
            <div class="row">
              <div class="col-8">
                <h2 class="name-user" style="color: #9d2449; font-weight: 900;">
                {{ track.user.displayName }}
                </h2>
              </div>
              <div class="col-4">
                <v-chip v-if="track.isActive" color="green" text-color="white"> Activo </v-chip>
              <v-chip v-else dark > Inactivo </v-chip>
              </div>

            </div>

            <div style="padding-top: 10px;" class=" dark-grey--text">Última actividad
              <span v-if="track" class="ml-2 font-weight-light"> hace {{
              track.trackedAt | dateFromNow
              }}</span>
            </div>

          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="color: rgb(157, 36, 73); font-weight: 500;" text @click="dialog = false;">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </GmapMap>
</template>
<script>
// import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import { to } from '@/helpers'
import { TRACKING_GET_TRACK } from '@/store/actions.type'
import { gmapApi } from 'vue2-google-maps'
import { mapGetters } from 'vuex'
const activeMarker = require('@/assets/img/maps/ic_location_pin.png')
const inactiveMarker = require('@/assets/img/maps/tracking_user_inactive.svg')
export default {
  name: 'STrackingMap',
  props: {},
  data() {
    return {
      zoom: 17,
      options: {
        clickableIcons: true,
        mapTypeControl: true,
        fullscreenControl: true,
        streetViewControl: true,
        zoomControl: true
      },
      center: {
        lat: 19.4101,
        lng: -99.1699
      },
      dialog: false,
      trackerUsers: null
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters([
      'getTrackingTracking',
      'getTrackingIsLoading',
      'getTrackingTrack'
    ])
  },
  methods: {
    getInitials(displayName) {
      if (!displayName) return ''
      return displayName.charAt(0)
    },
    getMarkerIcon(track) {
      var icon = track.isActive ? activeMarker : inactiveMarker
      return {
        url: icon,
        size: { width: 72, height: 72, f: 'px', b: 'px' },
        scaledSize: { width: 72, height: 72, f: 'px', b: 'px' }
      }
    },
    trakedUser(track) {
      const trackedClusters = track.getMarkers().map((marker) => this.getTrackingTracking.find((item) => item.id === marker.title))
      this.dialog = true; this.trackerUsers = trackedClusters
    },
    trackClicked(track) {
      this.getTrackingDetails(track)
    },
    async getTrackingDetails(track) {
      const [err] = await to(this.$store.dispatch(TRACKING_GET_TRACK, track.user.id))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  watch: {
    getTrackingTracking: function() {
      this.$refs.gmap.$mapPromise.then(map => {
        if (this.getTrackingTracking.length > 0) {
          const bounds = new this.google.maps.LatLngBounds()
          if (this.getTrackingTracking.length < 1) {
            bounds.extend({
              lat: 19.4326,
              lng: -99.1332
            })
          } else {
            for (const position of this.getTrackingTracking) {
              bounds.extend({
                lat: position.latitude,
                lng: position.longitude
              })
            }
          }
          this.center = bounds.getCenter()
          map.fitBounds(bounds)
        }
      })
    },
    getTrackingTrack: function() {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new this.google.maps.LatLngBounds()
        const track = this.getTrackingTrack
        bounds.extend(track.position)
        this.center = bounds.getCenter()
        this.zoom = 16
        map.fitBounds(bounds)
      })
    }

  },
  components: {}
}
</script>

<style scoped>

.modal-cluster:hover {
  background-position: 100% 0%;
  background-color: rgba(119, 125, 123, 0.7);
  opacity: .9;
  cursor: pointer;
}

.modal-cluster {
  border-top: solid rgba(119, 125, 123, 0.7) 1px;
  opacity: .9;
  display: flex;
  flex-basis: 150px;
  flex-grow: 1;
  padding: 8px;
}

.name-user{
  overflow: hidden;
  white-space: nowrap;
}

</style>
