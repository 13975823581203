<template>
    <v-navigation-drawer width="100%" style="height: 93vh;">
      <v-toolbar dense flat class="mt-2 mb-0">
        <v-toolbar-title>Ubicación tiempo real</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-skeleton-loader
        :loading="getTrackingIsLoading"
        :transition="transition"
        height="94"
        type="list-item-avatar-two-line"
      >
        <v-card flat class="mx-auto scroll">
          <s-tracking-sidebar-filters></s-tracking-sidebar-filters>
          <v-divider></v-divider>
          <v-list two-line dense subheader>
            <v-subheader>{{getTrackingTracking.length}} USUARIOS</v-subheader>
            <v-list-item-group>
              <template v-for="(track, index) in getTrackingTracking">
                <v-list-item :key="track.id" @click="trackClicked(track)">
                  <template v-slot:default="">
                    <v-list-item-avatar>
                      <v-avatar color="grey">
                        <span class="white--text headline">{{getInitials(track.user.displayName)}}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="track.user.displayName"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small outlined :color="getStatusColor(track)">{{getStatus(track)}}</v-chip>
                      <v-list-item-action-text v-text="dateFromNow(track.trackedAt)"></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index + 1 < getTrackingTracking.length" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-skeleton-loader>
    </v-navigation-drawer>
</template>
<script>
import STrackingSidebarFilters from './s-tracking-sidebar-filters.vue'
import { to } from '@/helpers'
import { TRACKING_GET_TRACKING, TRACKING_GET_TRACK } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
export default {
  name: 'STrackingSidebar',
  props: {},
  mounted() {
    this.getTracking()
  },
  data() {
    return {
      selectedStatus: null,
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters([
      'getTrackingTracking',
      'getTrackingIsLoading',
      'getTrackingStatusOptions',
      'getTrackingTracking'
    ])
  },
  methods: {
    async getTracking() {
      const [err] = await to(this.$store.dispatch(TRACKING_GET_TRACKING))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    trackClicked(track) {
      this.getTrackingDetails(track)
    },
    getInitials(displayName) {
      if (!displayName) return ''
      return displayName.charAt(0)
    },
    getStatus(track) {
      if (track.isActive) {
        return 'Activo'
      } else {
        return 'Inactivo'
      }
    },
    getStatusColor(track) {
      if (track.isActive) {
        return 'secondary'
      } else {
        return 'grey'
      }
    },
    async getTrackingDetails(track) {
      const [err] = await to(this.$store.dispatch(TRACKING_GET_TRACK, track.user.id))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  components: { STrackingSidebarFilters },
  mixins: [dateFromNow]
}
</script>
